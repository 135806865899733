import { useEffect, useState } from 'react';

const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 992,
    xl: 1200,
    xxl: 1400,
  },
};

const useMobileView = () => {
  const [mobileView, setMobileView] = useState(false);
  const [tabletView, setTabletView] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');

  useEffect(() => {
    // A function that sets the display state for the mobileview.
    function adjustMobileView() {
      if (window.innerWidth <= breakpoints.values.sm) {
        setMobileView(true);
      } else if (window.innerWidth <= breakpoints.values.md) {
        setTabletView(true);
      } else {
        setMobileView(false);
        setTabletView(false);
      }
    }

    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.xs
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }
    const runResize = () => {
      handleTabsOrientation();
      adjustMobileView();
    };

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', runResize);

    runResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', runResize);
  }, [tabsOrientation]);

  return {
    mobileView,
    tabletView,
    tabsOrientation,
  };
};

export default useMobileView;
