import React from 'react';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  IconButton,
} from '@mui/material';
import { TooltipContent } from './TooltipContent';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import '../styles/leftNav.css';
import useMobileView from '../utils/useMobileView';

const FolderWrapper = ({
  folders = [],
  children,
  isFolder,
  isEditing,
  handleSaveFolderEdit,
  handleNewTopic,
  handleUpdateFolderTitle,
  handleFolderDelete,
  refetchTopicsForFolder,
  defaultExpandedFolder,
}) => {
  const { mobileView } = useMobileView();

  const handleAddIconClick = (event, folderId) => {
    event.stopPropagation();
    handleNewTopic(folderId);
  };

  const handleChange = (folderId) => (event, isExpanded) => {
    if (isExpanded) {
      refetchTopicsForFolder(folderId);
    }
  };
  return (
    <>
      {folders.map((folder) => (
        <Accordion
          expanded={defaultExpandedFolder === folder.id}
          key={folder.id}
          onChange={handleChange(folder.id)}
          sx={{
            width: '100%',
            background: 'transparent',
            color: '#F5F5F5',
            opacity: 0.8,
            mt: 1,
          }}
        >
          <AccordionSummary
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* Conditional rendering for the dropdown icon */}
                {defaultExpandedFolder === folder.id ? (
                  <ExpandLessIcon
                    sx={{
                      color: '#999999',
                      transition: 'transform 0.2s',
                    }}
                  />
                ) : (
                  <KeyboardArrowRightSharpIcon
                    sx={{
                      color: '#999999',
                      transition: 'transform 0.2s',
                    }}
                  />
                )}
                <Typography
                  sx={{
                    color: '#999999',
                    fontSize: '16px',
                    fontWeight: '700',
                    marginLeft: 1,
                  }}
                >
                  {folder.title}
                </Typography>
              </Box>
              {defaultExpandedFolder === folder.id && (
                <>
                  <IconButton
                    onClick={(event) => {
                      handleAddIconClick(event, folder.id);
                    }}
                    sx={{ color: '#F5F5F5', marginLeft: 'auto' }}
                  >
                    <span className="glow-on-hover">
                      <AddIcon />
                    </span>
                  </IconButton>
                  <IconButton sx={{ color: '#F5F5F5', padding: 0 }}>
                    <TooltipContent
                      item={folder}
                      isFolder={isFolder}
                      onEdit={() => handleUpdateFolderTitle(folder)}
                      onDelete={(e) => handleFolderDelete(e, folder.id)}
                      handleSaveFolderEdit={handleSaveFolderEdit}
                      isEditing={isEditing}
                      placement={mobileView ? 'bottom' : 'right'}
                    />
                  </IconButton>
                </>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default FolderWrapper;
