import React, { useEffect, useRef } from 'react';
import { CodeBlock, dracula } from 'react-code-blocks';
import { Typography, Box } from '@mui/material';
import EastIcon from '@mui/icons-material/East';

import DownloadContent from '../utils/DownloadContent';
import CustomTypeWriter from './CustomTypeWriter';
import useMobileView from '../utils/useMobileView';
import '../styles/loaderLine.css';
import { ChatIconSVG } from './svg/chatIcon.svg.js';
import { EthereumIconSVG } from './svg/ethereumIcon.svg.js';
import { PictureIconSVG } from './svg/pictureIcon.svg.js';
import { AiAvatarSVG } from './svg/aiAvatar.svg.js';

function Chat({
  currentTopic,
  message,
  isLoggedIn,
  onSuggestedTextClick,
  isLoading,
}) {
  const msgEnd = useRef(null);
  const { mobileView, tabletView } = useMobileView();

  useEffect(() => {
    // Use setTimeout to ensure content is rendered before scrolling
    const timer = setTimeout(() => {
      msgEnd.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 120);

    return () => clearTimeout(timer);
  }, [message, currentTopic]);

  useEffect(() => {
    // Use setTimeout to ensure content is rendered before scrolling
    const timer = setTimeout(() => {
      msgEnd.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 120);

    return () => clearTimeout(timer);
  }, []);

  const isDummyMessageOnly =
    message?.length === 1 && message?.[0]?.topicId === '1';

  return (
    <div
      className="w-full flex items-center justify-center overflow-hidden overflow-y-auto"
      style={{
        height: mobileView ? '100%' : window.innerWidth >= 1900 ? '88%' : '80%',
        backgroundSize: '200px 200px',
        backgroundPosition: mobileView || tabletView ? '50% 75%' : '50% 85%',
        backgroundRepeat: 'no-repeat',
        scrollbarWidth: 'none',
      }}
    >
      <div
        style={{
          position: 'absolute',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          opacity: 0.05,
          pointerEvents: 'none',
        }}
        className="rounded"
      />
      <div
        className={`w-full flex flex-col h-full ${
          message?.[0]?.topicId === '1' ? 'items-center' : ''
        } `}
        style={{
          width: mobileView
            ? ''
            : window.innerWidth >= 1900
            ? '920px'
            : '740px',
        }}
      >
        {message.map((msg) => (
          <MessageWrapper
            key={msg.createdOn}
            link={msg.link}
            text={msg.text}
            prompt={msg.prompt}
            isBot={msg.isBot}
            isNewMessage={msg?.isNewMessage ?? false}
            mobileView={mobileView}
            tabletView={tabletView}
            type={msg.type}
          />
        ))}
        {isLoading && (
          <MessageWrapper
            isLoading={isLoading}
            key={new Date()}
            link={''}
            text={''}
            prompt={''}
            loader={<div class="loader-line"></div>}
            isBot={true}
            isNewMessage={false}
            mobileView={mobileView}
            tabletView={tabletView}
            type={'text'}
          />
        )}
        {(!isLoggedIn || isDummyMessageOnly) && (
          <Box
            sx={{
              display: 'flex',
              width: mobileView ? '100%' : '100%',
              maxWidth: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginTop: '16px',
              color: 'white',
              mb: 2,
              mt: 3,
              top: '120px',
              gap: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: { xs: 6 },
                width: '720px',
                height: '53px',
                lineHeight: '52.8px',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontFamily: 'Readex Pro',
                  marginRight: mobileView ? '8px' : '20px',
                  fontWeight: 700,
                  fontSize: { xs: '20px', md: '37px' },
                  lineHeight: { xs: '20px', md: '37px' },
                }}
              >
                Unlock the power of
              </Typography>

              <img
                style={{
                  width: mobileView
                    ? '65px'
                    : window.innerWidth >= 600
                    ? '150px'
                    : '177px',
                  height: mobileView ? '20px' : '37px',
                  objectFit: 'contain',
                  alignItems: 'center',
                  marginBottom: mobileView ? '-1px' : '4px',
                }}
                src="https://info.zkgpt.io/wp-content/uploads/2024/09/cropped-logo_slim.png"
                alt="gptloto"
              />
            </Box>
            <Typography
              variant="body1"
              sx={{
                width: mobileView ? '90%' : '720px',
                height: mobileView ? '100%' : '72px',
                font: 'Readex Pro',
                fontWeight: '400',
                fontSize: '16px',
                color: '#999999',
                lineHeight: '24px',
                alignItems: 'center',
              }}
            >
              Hey there, I'm zkGPT, the AI with an edge, crafted by zkGPT Dev.
              I'm here for your wildest queries, from the cosmos to the crypto.
              Got a question? Let's dive in and see what chaos we can cook up
              together!
            </Typography>
          </Box>
        )}
        {/* Suggestion cards */}
        {isDummyMessageOnly && (
          <Box
            sx={{
              width: mobileView ? '90%' : '480px',
              height: mobileView ? 'auto' : '248px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              top: '309px',
              left: '452px',
              gap: mobileView ? '10px' : '18px',
            }}
          >
            {[
              {
                header: 'Ask Anything',
                prompt: getRandomizedPromptByHeaderKey('Ask Anything'),
                emoji: <ChatIconSVG />,
              },
              {
                header: 'Scan Contracts',
                prompt: getRandomizedPromptByHeaderKey('Scan Contracts'),
                emoji: <EthereumIconSVG />,
              },
              {
                header: 'Generate Images',
                prompt: getRandomizedPromptByHeaderKey('Generate Images'),
                emoji: <PictureIconSVG />,
              },
            ].map(({ header, emoji, prompt }, index) => {
              const colors = ['#17B5AD', '#52BA69', '#8E55EA'];
              return (
                <Box
                  sx={{
                    gap: '20px',
                    padding: '10px',
                    background: 'rgb(17 24 39 / 0.1)',
                    alignItems: 'center',
                    height: mobileView ? '55px' : '72px',
                    width: mobileView ? '90%' : '480px',
                    display: 'flex',
                    minWidth: mobileView ? 100 : 300,
                    cursor: isLoggedIn ? 'pointer' : 'auto',
                    border: '1px solid #666666',
                    borderRadius: mobileView ? '16px' : '20px',
                    transition: 'transform 0.3s ease, background 0.3s ease',
                    '&:hover': {
                      background: isLoggedIn
                        ? '#088C88'
                        : 'rgb(17 24 39 / 0.1)',
                      transform: isLoggedIn ? 'scale(1.05)' : 'auto',
                    },
                  }}
                  key={header}
                  onClick={() =>
                    onSuggestedTextClick ? onSuggestedTextClick(prompt) : null
                  }
                >
                  <Box
                    sx={{
                      width: mobileView ? '35px' : '55px',
                      height: mobileView ? '35px' : '55px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: `${colors[index]}14`,
                      borderRadius: mobileView ? '8px' : '12px',
                      padding: 1,
                      gap: '10px',
                    }}
                  >
                    {emoji}
                  </Box>

                  <Typography
                    variant="body2"
                    sx={{
                      width: '336px',
                      height: '24px',
                      paddingBottom: 0,
                      color: '#f5f5f5',
                      opacity: 0.7,
                      font: 'Readex Pro',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '24px',
                    }}
                  >
                    {header}
                  </Typography>
                  <EastIcon
                    sx={{
                      width: '24px',
                      height: '24pz',
                      ml: 'auto',
                      mr: 2,
                      color: '#666666',
                      top: '4.91px',
                      left: '12.64px',
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        )}
        <div ref={msgEnd} />
      </div>
    </div>
  );
}

export default Chat;

const MessageWrapper = ({
  isBot,
  link,
  text,
  loader,
  prompt,
  type,
  isNewMessage = false,
  mobileView = false,
  tabletView = false,
  isLoading = false,
}) => {
  return (
    <>
      {/* Loader */}
      {loader && isLoading && (
        <span
          className={
            'flex items-start justify-start gap-2 lg:gap-5 my-2 bg-gray-800/50 p-3 rounded-md'
          }
          style={{ width: '80%' }}
        >
          <Box className="rounded object-cover">
            <AiAvatarSVG />
          </Box>
          <span className="rounded-md px-4 py-4">
            <div className="text-white text-[15px]">{loader}</div>
          </span>
        </span>
      )}

      {/* User Prompt */}
      {prompt?.length > 0 && (
        <span
          className={
            isBot
              ? 'flex max-w-[480px] items-end justify-end gap-2 lg:gap-5 my-2 bg-gray-800/80 p-3 rounded-md'
              : 'flex items-end justify-end gap-2 lg:gap-5 my-2 p-3'
          }
        >
          <span className="rounded-md bg-teal-600 bg-opacity-20 px-4 py-4 max-w-[40rem]">
            <div
              className="text-white text-[15px] "
              style={{
                whiteSpace: 'pre-wrap',
                overflowX: 'hidden',
              }}
            >
              {renderText(prompt)}
            </div>
          </span>
        </span>
      )}
      {/* AI Response */}
      {(text?.length > 0 || link) && (
        <span
          className={
            isBot
              ? 'flex w-[720px] items-start justify-start gap-2 lg:gap-5 my-2 bg-gray-800/50 p-3 rounded-lg'
              : 'flex w-[720px] items-start justify-start gap-2 lg:gap-5 my-2 p-3 bg-gray-800/50 rounded-lg'
          }
          style={{ width: '100%' }}
        >
          <Box className="rounded object-cover">
            <AiAvatarSVG />
          </Box>
          {link ? (
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', right: 8, top: 8 }}>
                <DownloadContent contentName={prompt} contentUrl={link} />
              </div>
              <img
                src={link}
                alt="generated content"
                className="rounded object-cover"
                width={mobileView || tabletView ? '338px' : '500px'}
                height={mobileView || tabletView ? '338px' : '500px'}
                style={{ opacity: 1, zIndex: 1 }}
              />
              <p
                className="text-white text-[14px]"
                style={{ marginTop: '4px' }}
              >
                Here is the generated {type === 'meme' ? 'meme' : 'image'} for
                you.
              </p>
            </div>
          ) : (
            <div
              className="text-white text-[15px]"
              style={{
                whiteSpace: 'pre-wrap',
                overflow: 'auto',
                width: mobileView || tabletView ? '80vw' : 'auto',
              }}
            >
              <CustomTypeWriter
                parts={renderText(text)}
                isNewMessage={isNewMessage}
              />
            </div>
          )}
        </span>
      )}
    </>
  );
};

function renderText(text) {
  // Ensure the content is rendered only once by splitting at line breaks first
  const uniqueLines = new Set(text.split('\n')); // Using Set to avoid duplicates
  const processedText = Array.from(uniqueLines).join('\n');

  // Now proceed with the usual rendering flow
  const codeBlockRegex = /```([a-zA-Z]*)\n([\s\S]*?)```/g;
  const parts = [];
  let lastIndex = 0;
  let match;

  // Handle code blocks first
  while ((match = codeBlockRegex.exec(processedText)) !== null) {
    const [fullMatch, language, code] = match;

    if (lastIndex < match.index) {
      const textBeforeCode = processedText.substring(lastIndex, match.index);
      parts.push(...splitTextWithInlineCode(textBeforeCode));
    }

    parts.push(
      <CodeBlock
        key={`code-block-${match.index}`}
        text={code.trim()}
        language={language}
        showLineNumbers={true}
        theme={dracula}
        wrapLongLines={false}
        codeContainerStyle={{ padding: '16px 0px' }}
        customStyle={{
          margin: '8px 0px',
          marginBottom: 0,
          width: '100%',
        }}
      />,
    );

    lastIndex = codeBlockRegex.lastIndex;
  }

  if (lastIndex < processedText.length) {
    const remainingText = processedText.substring(lastIndex);
    parts.push(...splitTextWithInlineCode(remainingText));
  }

  return parts;
}

function splitTextWithInlineCode(text) {
  const inlineCodeRegex = /`([^`]+)`/g;

  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = inlineCodeRegex.exec(text)) !== null) {
    if (lastIndex < match.index) {
      const textBeforeCode = text.substring(lastIndex, match.index);
      parts.push(...splitTextWithBoldAndLinks(textBeforeCode));
    }

    const codeText = match[1];
    parts.push(
      <code
        key={`inline-code-${match.index}`}
        style={{
          backgroundColor: '#0D948833',
          padding: '2px 4px',
          borderRadius: '4px',
        }}
      >
        {codeText}
      </code>,
    );

    lastIndex = inlineCodeRegex.lastIndex;
  }

  if (lastIndex < text.length) {
    const remainingText = text.substring(lastIndex);
    parts.push(...splitTextWithBoldAndLinks(remainingText));
  }

  return parts;
}

function splitTextWithBoldAndLinks(text) {
  const boldTextRegex = /\*\*(.*?)\*\*/g;

  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = boldTextRegex.exec(text)) !== null) {
    if (lastIndex < match.index) {
      const textBeforeBold = text.substring(lastIndex, match.index);
      parts.push(...splitTextWithLinks(textBeforeBold));
    }

    const boldText = match[1];
    parts.push(
      <strong key={`bold-${match.index}`} style={{ fontWeight: 'bold' }}>
        {boldText}
      </strong>,
    );

    lastIndex = boldTextRegex.lastIndex;
  }

  if (lastIndex < text.length) {
    const remainingText = text.substring(lastIndex);
    parts.push(...splitTextWithLinks(remainingText));
  }

  return parts;
}

function splitTextWithLinks(text) {
  const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;

  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = linkRegex.exec(text)) !== null) {
    if (lastIndex < match.index) {
      const textBeforeLink = text.substring(lastIndex, match.index);
      parts.push(textBeforeLink);
    }

    const linkText = match[1];
    const url = match[2];
    parts.push(
      <a
        key={`link-${match.index}`}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#1E90FF' }}
      >
        {linkText}
      </a>,
    );

    lastIndex = linkRegex.lastIndex;
  }

  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }

  return parts;
}

const askMeAnythingLists = [
  'Explain me how human evolution works.',
  'Teach me in basic form about Ethereum tokens',
  'Let me know how to buy any Ethereum tokens using metamask and Uniswap',
  'Best cities in Finland',
  'Best Things to do in Lapland',
  'Explain how about Node.js',
  'Teach me a basic mathematics algebra.',
  'translate "Hello, good morning!" to Chinese',
];
const scanContractsLists = [
  'Tell me about $SHIB token market data.',
  'Get me market data about $Neiro',
  'Scan contract 0xe0f63a424a4439cbe457d80e4f4b51ad25b2c56c',
  'Audit status of token $BITCOIN',
];
const generateImagesLists = [
  'Generate Shiba Inu dog by Sakura tree.',
  'Get me image of a batman',
  'Image of a black Lambo',
  'A bird flying over an ocean and watching the forests.',
  'Generate an image of flying kites over the blue sea.',
];

const getRandomizedPromptByHeaderKey = (headerKey) => {
  switch (headerKey) {
    case 'Ask Anything':
      return askMeAnythingLists[
        Math.floor(Math.random() * askMeAnythingLists.length)
      ];
    case 'Scan Contracts':
      return scanContractsLists[
        Math.floor(Math.random() * scanContractsLists.length)
      ];
    case 'Generate Images':
      return generateImagesLists[
        Math.floor(Math.random() * generateImagesLists.length)
      ];
    default:
      return 'Tell me what to do when I have a cold.';
  }
};
