export const EthereumIconSVG = () => {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 24 24"
      fill="#52BA69"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2497 2.25C12.4863 2.25 12.709 2.36168 12.8506 2.55128L19.3835 11.3007C19.5207 11.4845 19.5663 11.7211 19.5072 11.9427C19.448 12.1644 19.2907 12.3468 19.0801 12.4378L12.5472 15.2615C12.3573 15.3436 12.142 15.3436 11.9521 15.2615L5.41924 12.4378C5.20864 12.3468 5.05129 12.1644 4.99215 11.9427C4.93301 11.7211 4.97858 11.4845 5.11584 11.3007L11.6487 2.55128C11.7903 2.36168 12.013 2.25 12.2497 2.25ZM6.88565 11.4375L12.2497 13.756L17.6137 11.4375L12.2497 4.25358L6.88565 11.4375Z"
        fill="#52BA69"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2497 2.25C12.4863 2.25 12.7091 2.36168 12.8506 2.55128L19.3835 11.3007C19.5207 11.4845 19.5663 11.7211 19.5072 11.9427C19.448 12.1644 19.2907 12.3468 19.0801 12.4378L12.5472 15.2615C12.3573 15.3436 12.142 15.3436 11.9521 15.2615L5.41925 12.4378C5.20865 12.3468 5.0513 12.1644 4.99216 11.9427C4.93302 11.7211 4.97859 11.4845 5.11585 11.3007L11.6487 2.55128C11.7903 2.36168 12.013 2.25 12.2497 2.25ZM6.88566 11.4375L12.2497 13.756L17.6137 11.4375L12.2497 4.25358L6.88566 11.4375ZM5.09241 14.2969C5.29332 13.9949 5.68148 13.88 6.01438 14.0239L12.2497 16.719L18.485 14.0239C18.8179 13.88 19.206 13.9949 19.4069 14.2969C19.6078 14.5988 19.5639 15.0012 19.3026 15.2527L12.7698 21.5404C12.4794 21.8199 12.02 21.8199 11.7296 21.5404L5.19672 15.2527C4.93542 15.0012 4.89151 14.5988 5.09241 14.2969ZM9.22093 17.044L12.2497 19.9591L15.2784 17.044L12.5472 18.2245C12.3573 18.3066 12.142 18.3066 11.9521 18.2245L9.22093 17.044Z"
        fill="#52BA69"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.25 2.25C12.6642 2.25 13 2.58579 13 3V14.5731C13 14.9873 12.6642 15.3231 12.25 15.3231C11.8358 15.3231 11.5 14.9873 11.5 14.5731V3C11.5 2.58579 11.8358 2.25 12.25 2.25Z"
        fill="#52BA69"
      />
    </svg>
  );
};
