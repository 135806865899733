import axios from 'axios';

const API_ROOT = process.env.REACT_APP_API_ROOT;
const coinGeckoAPI = `https://api.coingecko.com/api/v3/simple/price?ids=ethereum,bitcoin,ripple,binancecoin,dogecoin,shiba-inu,the-open-network,avalanche-2,solana&vs_currencies=usd&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true&include_last_updated_at=true&precision=2`;

export const createWallet = async (wallet) => {
  try {
    if (!wallet) {
      return;
    }
    const response = await axios.post(`${API_ROOT}/api/wallets`, {
      wallet,
    });
    return response.data;
  } catch (error) {
    //
  }
};

export const createNewTopicInFolder = async (payload) => {
  try {
    if (!(payload.folderId && payload.title)) {
      return;
    }
    const response = await axios.post(`${API_ROOT}/api/topics`, payload, {
      headers: {
        Authorization: localStorage.getItem('wallet'),
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    //
  }
};

export const updateTopic = async (topic) => {
  try {
    const response = await axios.put(
      `${API_ROOT}/api/topics/${topic.topicId}`,
      topic,
      {
        headers: {
          Authorization: localStorage.getItem('wallet'),
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('cannot update Topic');
  }
};

export const deleteTopicById = async (topicIdToDelete) => {
  try {
    const res = await axios.delete(
      `${API_ROOT}/api/topics/${topicIdToDelete}`,
      {
        headers: {
          Authorization: localStorage.getItem('wallet'),
        },
      },
    );
    return res;
  } catch (error) {
    console.error('Error deleting Topic');
  }
};

export const createActivityInTopic = async (payload) => {
  try {
    if (!(payload.topicId && payload.prompt)) {
      return;
    }
    const response = await axios.post(
      `${API_ROOT}/api/topics/${payload.topicId}/activity`,
      payload,
      {
        headers: {
          Authorization: localStorage.getItem('wallet'),
        },
      },
    );
    return response.data;
  } catch (error) {
    //
  }
};

export const fetchAllFoldersByWallet = async () => {
  try {
    if (!localStorage.getItem('wallet')) {
      return;
    }
    const response = await axios.get(`${API_ROOT}/api/wallets/folders`, {
      headers: {
        Authorization: localStorage.getItem('wallet'),
      },
    });
    return response.data;
  } catch (error) {
    //
  }
};

export const createNewFolder = async (payload) => {
  try {
    if (!(payload.wallet && payload.title)) {
      return;
    }
    await axios.post(`${API_ROOT}/api/folders`, payload, {
      headers: {
        Authorization: localStorage.getItem('wallet'),
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Error creating folder:', error);
    throw error;
  }
};

export const updateFolder = async (folder) => {
  try {
    const response = await axios.put(
      `${API_ROOT}/api/folders/${folder.folderId}`,
      folder,
      {
        headers: {
          Authorization: localStorage.getItem('wallet'),
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Faced error while updating Folder!');
  }
};

export const deleteFolderById = async (deleteFolderId) => {
  try {
    const res = await axios.delete(
      `${API_ROOT}/api/folders/${deleteFolderId}`,
      {
        headers: {
          Authorization: localStorage.getItem('wallet'),
        },
      },
    );
    return res;
  } catch (error) {
    console.error('Error deleting Folder');
  }
};

export const fetchAllTopicsByFolderId = async (folderId) => {
  try {
    if (!folderId) {
      return;
    }
    const response = await axios.get(
      `${API_ROOT}/api/folders/${folderId}/topics`,
      {
        headers: {
          Authorization: localStorage.getItem('wallet'),
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching topics:', error);
  }
};

export const fetchAllActivitiesByTopicId = async (topicId) => {
  try {
    if (!topicId) {
      return;
    }
    const response = await axios.get(`${API_ROOT}/api/topics/${topicId}`, {
      headers: {
        Authorization: localStorage.getItem('wallet'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching activities:', error);
  }
};

export const getWalletTokenAmount = async (walletAddress) => {
  try {
    if (!walletAddress) {
      return;
    }
    const response = await axios.get(
      `${API_ROOT}/api/wallets/${walletAddress}/balance`,
    );
    return response.data?.balance ?? '0';
  } catch (error) {
    //
  }
};

export const getOverallMarketStats = async () => {
  try {
    const response = await axios.get(coinGeckoAPI);
    return response.data ?? {};
  } catch (error) {
    return {};
  }
};
