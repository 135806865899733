export const SidebarIconSVG = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 19V5H6C4.89543 5 4 5.89543 4 7V17C4 18.1046 4.89543 19 6 19H12ZM12 3H18C20.2091 3 22 4.79086 22 7V17C22 19.2091 20.2091 21 18 21H12H6C3.79086 21 2 19.2091 2 17V7C2 4.79086 3.79086 3 6 3H12Z"
        fill="white"
      />
    </svg>
  );
};
