export const ChatIconSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32px"
      height="32px"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8.04233 20.0853C9.23677 20.6711 10.58 21 12.0001 21C16.9707 21 21.0001 16.9706 21.0001 12C21.0001 7.02939 16.9706 2.99988 12 2.99988C7.02944 2.99988 3 7.02931 3 11.9999C3 13.42 3.32891 14.7633 3.9147 15.9577C4.43146 17.0332 3.93662 18.2124 3.67471 19.2912C3.52546 19.8829 4.11717 20.4746 4.70879 20.3253C5.78685 20.0533 6.96586 19.5681 8.04233 20.0853Z"
        stroke="#17B5AD"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0541 13.9784L9.95311 13.705C9.57225 12.6742 8.76073 11.8615 7.7314 11.4801L7.45847 11.3789L7.7314 11.2778C8.76073 10.8963 9.57225 10.0836 9.95311 9.05282L10.0541 8.77942L10.1552 9.05282C10.536 10.0836 11.3475 10.8963 12.3769 11.2778L12.6499 11.3789L12.3769 11.4801C11.3475 11.8615 10.536 12.6742 10.1552 13.705L10.0541 13.9784Z"
        fill="#17B5AD"
        stroke="#17B5AD"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.3648 15.2205C15.1932 14.6559 14.752 14.214 14.1882 14.0421C14.752 13.8702 15.1932 13.4283 15.3648 12.8636C15.5365 13.4283 15.9777 13.8702 16.5415 14.0421C15.9777 14.214 15.5365 14.6559 15.3648 15.2205Z"
        stroke="#17B5AD"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
