import React, { useState, useEffect } from 'react';

const CustomTypewriter = ({ parts, isNewMessage = false, typeSpeed = 10 }) => {
  const [displayedParts, setDisplayedParts] = useState([]);
  const [currentPart, setCurrentPart] = useState('');
  const [currentPartIndex, setCurrentPartIndex] = useState(0);

  useEffect(() => {
    if (!isNewMessage) {
      // If not a new message, display all parts immediately
      setDisplayedParts(parts);
      return;
    }

    if (currentPartIndex < parts.length) {
      const current = parts[currentPartIndex];

      if (typeof current === 'string') {
        let index = 0;
        const interval = setInterval(() => {
          setCurrentPart((prev) => prev + current[index]);
          index += 1;
          if (index === current.length) {
            clearInterval(interval);
            setDisplayedParts((prev) => [...prev, current]);
            setCurrentPart('');
            setCurrentPartIndex((prev) => prev + 1);
          }
        }, typeSpeed);
        return () => clearInterval(interval);
      } else if (React.isValidElement(current)) {
        // Handle non-string elements (e.g., code blocks) immediately
        setDisplayedParts((prev) => [...prev, current]);
        setCurrentPartIndex((prev) => prev + 1);
      }
    }
  }, [currentPartIndex, parts, typeSpeed, isNewMessage]);

  return (
    <div>
      {displayedParts.map((part, index) => (
        <span key={index}>{part}</span>
      ))}
      {currentPart && <span>{currentPart}</span>}
    </div>
  );
};

export default CustomTypewriter;
