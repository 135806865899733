import React from 'react';
import { truncate } from 'lodash';
import { FaShare, FaDownload, FaLink } from 'react-icons/fa6';

const DownloadContent = ({ contentUrl, contentName }) => {
  return (
    <div
      style={{
        borderRadius: '24px',
        display: 'flex',
        alignItems: 'center',
        padding: '4px 8px',
        // background: '#0D948833',
        background: '#1A1A1A',
        opacity: 1,
      }}
    >
      <div
        style={{
          cursor: 'pointer',
          marginRight: '12px',
          color: '#bbb',
          fontSize: 14,
        }}
        onClick={async (e) => {
          await downloadContent(contentUrl, contentName);
        }}
      >
        <FaDownload></FaDownload>
      </div>
      <div
        style={{ cursor: 'pointer', color: '#bbb', fontSize: 16 }}
        onClick={() => {
          if (contentUrl) {
            window.open(contentUrl, '_blank');
          }
        }}
      >
        <FaLink></FaLink>
      </div>
    </div>
  );
};

export default DownloadContent;

export const downloadContent = async (imageUrl, imageName) => {
  try {
    const response = await fetch(
      `${imageUrl}?cachebuster=${new Date().getTime()}`,
      {
        method: 'GET',
        mode: 'cors', // Ensures CORS is handled
        cache: 'no-cache', // Prevents caching issues
      },
    );

    if (!response.ok) {
      throw new Error('Failed to fetch the file');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;

    a.download = truncate(imageName, {
      length: 30,
      separator: '',
    });
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    //
  }
};
