import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { ContextApp } from '../utils/Context';
import { useAppKitAccount } from '@reown/appkit/react';
import { createWallet, getWalletTokenAmount } from '../utils/API';

const BALANCE_LIMIT = parseInt(process.env.REACT_APP_BALANCE_LIMIT || '0');
const TOKEN_SUPPLY = parseInt(process.env.REACT_APP_TOKEN_SUPPLY || '0');

const getWalletTier = (balance = 0) => {
  const holdingPercentage = (balance * 100) / TOKEN_SUPPLY;
  if (holdingPercentage >= 1) {
    return 'Whale 🐳';
  } else if (holdingPercentage >= 0.5) {
    return 'Fish 🐟';
  } else if (holdingPercentage >= 0.01) {
    return 'Shrimp 🦐';
  } else {
    return 'Free 🐢';
  }
};

const WalletConnect = () => {
  const { setIsLoggedIn, isLoggedIn, setCurrentWallet } =
    useContext(ContextApp);
  const { address, isConnected } = useAppKitAccount();

  // console.log(address);
  const [tokenBalance, setTokenBalance] = useState(
    parseInt(localStorage.getItem('tokenbalance') || '0', 10),
  );
  const [balanceCreated, setBalanceCreated] = useState(
    localStorage.getItem('balance-created')
      ? new Date(localStorage.getItem('balance-created'))
      : null,
  );
  const [currentTier, setCurrentTier] = useState(null);

  const { data: balanceData, refetch: fetchWalletTokenBalance } = useQuery(
    'getWalletTokenAmount',
    () => getWalletTokenAmount(address),
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (address) {
      setCurrentWallet(address);
    } else {
      setCurrentWallet('');
    }
  }, [address]);

  useEffect(() => {
    if (isConnected && address) {
      if (!isLoggedIn) {
        localStorage.clear();
        getBalance();
        return;
      }

      const now = new Date();
      const timeDifference = now - new Date(balanceCreated);
      const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      if (timeDifference >= twentyFourHours) {
        localStorage.clear();
        getBalance();
      }

      if (
        timeDifference < twentyFourHours &&
        !isLoggedIn &&
        tokenBalance >= BALANCE_LIMIT
      ) {
        setIsLoggedIn(true);
        setCurrentTier(getWalletTier(tokenBalance));
      }
    }

    if (!isConnected && !address) {
      localStorage.clear();
      setIsLoggedIn(false);
      setCurrentTier(null);
    }
  }, [address, isConnected, isLoggedIn]);

  const getBalance = async () => {
    try {
      const formattedBalanceRes = await fetchWalletTokenBalance();
      const formattedBalance =
        parseInt(formattedBalanceRes?.data ?? '0', 10) || 0;

      // Create or track wallet
      await createWallet(address);

      const walletTier = getWalletTier(formattedBalance);

      if (formattedBalance >= BALANCE_LIMIT) {
        localStorage.setItem('wallet', address);
        localStorage.setItem('tokenbalance', formattedBalance);
        localStorage.setItem('balance-created', new Date().toISOString());
        setIsLoggedIn(true);
        setTokenBalance(formattedBalance);
        setBalanceCreated(new Date());
      } else {
        localStorage.clear();
        setIsLoggedIn(false);
      }
      setCurrentTier(walletTier);
    } catch (e) {
      localStorage.clear();
      setIsLoggedIn(false);
      setCurrentTier(null);
      setCurrentWallet('');
    }
  };
  const isWhale = currentTier?.includes('Whale');
  const isFish = currentTier?.includes('Fish');
  const isShrimp = currentTier?.includes('Shrimp');
  const isFree = currentTier?.includes('Free');
  const getHoldingBasedColor = () => {
    if (isWhale) {
      return '#009688';
    }
    if (isFish) {
      return '#4caf50';
    }
    if (isShrimp) {
      return '#2196f3';
    }
    return '#ff9800';
  };
  return (
    <div>
      <appkit-button size="sm" />
      {currentTier && (
        <div
          style={{
            textAlign: 'center',
            background: getHoldingBasedColor(),
            padding: '4px 8px',
          }}
        >
          <b>Current Tier:</b> {currentTier}
        </div>
      )}
    </div>
  );
};

export default WalletConnect;
