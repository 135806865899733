import React from 'react';
import ReactDOM from 'react-dom/client';
import AppContext from './utils/Context';
import { createAppKit } from '@reown/appkit/react';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';
import { mainnet } from '@reown/appkit/networks';

import { QueryClientProvider, QueryClient } from 'react-query';
import reportWebVitals from './reportWebVitals';

import Home from './Page/Home';
import './index.css';

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || '';
const networks = [mainnet];

const metadata = {
  name: 'zkGPT DApp',
  description: 'zkGPT DApp',
  url: 'https://zkgpt.io',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

// 4. Create a AppKit instance
createAppKit({
  adapters: [new EthersAdapter()],
  networks,
  metadata,
  themeMode: 'dark',
  projectId,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  features: {
    analytics: false,
    email: false,
    socials: [],
    showWallets: true,
    walletFeatures: false,
  },
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppContext>
        <Home />
      </AppContext>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
