import React, { useContext, useEffect, useRef, useState } from 'react';
import { FiTrash, FiEdit } from 'react-icons/fi';
import { Box, Tooltip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { truncate } from 'lodash';
import { ContextApp } from '../utils/Context';

export const TooltipContent = ({
  index,
  item,
  currentPageTopicId,
  onEdit,
  onDelete,
  hoveredItemId,
  isEditing,
  isFolder,
  openDialog,
  openEditDialog,
  placement = 'right',
}) => {
  const colors = ['#17B5AD', '#52BA69', '#8E55EA', '#FF5722', '#FFC107'];

  const [currentTopicSettings, setCurrentTopicSettings] = React.useState();
  const { Mobile, setMobile } = useContext(ContextApp);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setCurrentTopicSettings(null);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, []);

  const isTooltipOpen =
    currentTopicSettings === item.id && !openDialog && !openEditDialog;

  return (
    <Tooltip
      open={isTooltipOpen}
      PopperProps={{
        sx: {
          '.MuiTooltip-tooltip': {
            border: '1px solid #111827',
            padding: '2px',
            marginTop: '0px !important',
          },
        },
      }}
      placement={placement}
      arrow
      title={
        <div
          className="bg-gray-900"
          style={{
            minWidth: '180px',
            padding: '16px',
          }}
          ref={tooltipRef}
        >
          <div
            className={`rounded w-full py-2 px-2 text-xs my-2 flex gap-1 items-center cursor-pointer hover:bg-teal-800 transition-all duration-300 overflow-hidden truncate whitespace-nowrap bg-gray-700`}
            onClick={(e) => {
              onEdit(e, item);
              setCurrentTopicSettings(null);
            }}
          >
            <FiEdit />
            <span className="text-sm">Edit</span>
          </div>
          <div
            className={`rounded w-full py-2 px-2 text-xs my-2 flex gap-1 items-center cursor-pointer hover:bg-teal-800 transition-all duration-300 overflow-hidden truncate whitespace-nowrap bg-gray-700`}
            onClick={(e) => {
              onDelete(e, item.id);
              setCurrentTopicSettings(null);
            }}
          >
            <FiTrash />
            <span className="text-sm">Delete</span>
          </div>
        </div>
      }
    >
      <span
        className={`rounded w-full py-2 px-2 text-xs my-1 flex gap-1 items-center justify-between cursor-pointer transition-all duration-300 overflow-hidden truncate whitespace-nowrap ${
          item.id === currentPageTopicId ? 'bg-gray-700' : ''
        } ${!isFolder ? 'hover:bg-gray-800' : ''}`}
        onClick={() => {
          setMobile(!Mobile);
        }}
        value={item.title}
        style={{ position: 'relative' }}
      >
        {!isFolder && (
          <span className="flex w-full gap-2 text-base items-center">
            <Box
              sx={{
                width: '16px',
                height: '16px',
                borderRadius: '4px',
                marginLeft: 0,
                padding: 0,
                backgroundColor: colors[index % colors.length],
              }}
            />
            <span
              className="text-sm"
              style={{
                color: item.id === currentPageTopicId ? '#FFFFFF' : '#999999',
                fontSize: '16px',
              }}
            >
              {truncate(item.title, {
                length: 26,
              })}
            </span>
          </span>
        )}
        {(item.id === hoveredItemId || item.id === currentPageTopicId) &&
          !isEditing &&
          !isFolder && (
            <span
              style={{
                position: 'absolute',
                paddingLeft: 2,
                right: '0px',
              }}
              className="flex items-center"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setCurrentTopicSettings(item.id);
              }}
            >
              <MoreHorizIcon />
            </span>
          )}
        {isFolder && (
          <span
            className="flex items-center"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setCurrentTopicSettings(item.id);
            }}
          >
            <MoreHorizIcon />
          </span>
        )}
      </span>
    </Tooltip>
  );
};
