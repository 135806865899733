export const PictureIconSVG = () => {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3773 3H7.78216C4.84281 3 3 5.08119 3 8.02638V15.9736C3 18.9188 4.83405 21 7.78216 21H16.2159C19.165 21 21 18.9188 21 15.9736V10.9453"
        stroke="#8E55EA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8071 8.77478H13.8171"
        stroke="#8E55EA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.98191 16.1226L9.67218 11.7539C9.93974 11.319 10.5644 11.2986 10.8601 11.714L12.5414 14.0754C12.81 14.4539 13.3636 14.4782 13.6643 14.1241L14.2685 13.4167C14.5827 13.048 15.1656 13.0937 15.4195 13.5072L17.02 16.1235C17.311 16.5993 16.9694 17.2094 16.4119 17.2094H7.58904C7.03153 17.2094 6.68904 16.5974 6.98191 16.1226Z"
        fill="#8E55EA"
        stroke="#8E55EA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.1169 8.77478L18.2291 8.47113C18.6521 7.32617 19.5535 6.42344 20.6968 5.99977L21 5.88741L20.6968 5.77505C19.5535 5.35137 18.6521 4.44864 18.2291 3.30368L18.1169 3L18.0046 3.30368C17.5816 4.44864 16.6802 5.35137 15.5369 5.77505L15.2336 5.88741L15.5369 5.99977C16.6802 6.42344 17.5816 7.32617 18.0046 8.47113L18.1169 8.77478Z"
        fill="#8E55EA"
        stroke="#8E55EA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
