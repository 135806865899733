import { createContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { generateImage } from './OpenAi';
import { createActivityInTopic } from './API';
import { fetchAllActivitiesByTopicId } from './API';

export const ContextApp = createContext();

const defaultMessageObject = {
  topicId: '1',
  text: `Hey there, I'm zkGPT, the AI with an edge, crafted by zkGPT Dev. I'm here for your wildest queries, from the cosmos to the crypto. Got a question? Let's dive in and see what chaos we can cook up together!.`,
  isBot: true,
};

const AppContext = ({ children }) => {
  const [showSlide, setShowSlide] = useState(false);
  const [Mobile, setMobile] = useState(false);
  const [chatValue, setChatValue] = useState('');
  const [currentTopic, setCurrentTopic] = useState();
  const [currentWallet, setCurrentWallet] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [refetchTopics, setRefetchTopics] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState([defaultMessageObject]);

  const handleMessageReset = () => {
    setMessage([defaultMessageObject]);
  };

  // Query Click function
  const handleQuery = async (e, topicId) => {
    const text = e.target.innerText;
    return;
    setMessage([...message, { text, isBot: false, topicId }]);
    const res = await createActivityInTopic({
      topicId,
      currentTopic,
      prompt: text,
    });
    setMessage([
      ...message,
      { text, isBot: false, topicId },
      { text: res, isBot: true, topicId },
    ]);
  };
  return (
    <ContextApp.Provider
      value={{
        showSlide,
        setShowSlide,
        Mobile,
        setMobile,
        chatValue,
        setChatValue,
        handleMessageReset,
        message,
        handleQuery,
        setIsLoggedIn,
        isLoggedIn,
        currentTopic,
        setCurrentTopic,
        isLoading,
        setIsLoading,
        setRefetchTopics,
        refetchTopics,
        setCurrentWallet,
        currentWallet,
      }}
    >
      {children}
    </ContextApp.Provider>
  );
};
export default AppContext;
