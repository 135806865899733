export const TwitterIconSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7902 4H19.4659L13.5913 10.7889L20.4545 20H15.0686L10.8519 14.4024L6.02469 20H3.34896L9.57256 12.738L3 4H8.51974L12.3308 9.11306L16.7902 4ZM15.8539 18.4057H17.3371L7.74037 5.53299H6.14659L15.8539 18.4057Z"
        fill="#999999"
      />
    </svg>
  );
};
