export const TelegramIconSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.533 16.5888L10.3211 18.6442C10.0504 18.8966 9.60976 18.6893 9.63472 18.3197C9.70384 17.2752 9.81136 15.6883 9.88336 14.8061C9.89488 14.6669 10.012 14.5114 10.1234 14.4106C12.7067 12.0682 15.2959 9.73151 17.884 7.39297C17.98 7.30657 18.0933 7.09344 18.0338 6.98112C17.9666 6.8544 17.6565 6.8928 17.5279 6.97344C14.2408 9.0432 10.9538 11.112 7.67151 13.1885C7.46991 13.3152 7.2232 13.3459 6.99663 13.273C5.64303 12.8381 4.28175 12.4262 2.92527 11.9981C2.61327 11.8992 2.24271 11.8013 2.20719 11.4144C2.16975 11.0102 2.51631 10.8182 2.82928 10.6925C4.09072 10.1837 5.3608 9.69695 6.62895 9.20736C11.1909 7.4496 15.7528 5.6928 20.3147 3.93601C20.4674 3.87745 20.6709 3.84097 20.8303 3.80929C21.3083 3.71329 21.7384 3.95329 21.7855 4.43713C21.821 4.79137 21.7586 5.18593 21.6847 5.53633C20.7343 10.057 19.7714 14.5757 18.8095 19.0954C18.5503 20.3165 17.9224 20.5478 16.9115 19.8019C15.6088 18.84 12.533 16.5888 12.533 16.5888Z"
        fill="#999999"
      />
    </svg>
  );
};
